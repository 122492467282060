<template>
  <c-box
    margin-top="24px"
  >
    <MealScheduleContainer
      :is-edited="isEdited"
      :data="data"
      :is-program-consultation-basic="isProgramConsultationBasic"
    />
  </c-box>
</template>

<script>
import { reqAdmin_mealPlans_preview } from '@/requests/dietela-api/admin/meal-plan'
import { CBox } from '@chakra-ui/vue'
import MealScheduleContainer from '@/components/meal-plan/meal-plan-schedule/container.vue'

export default {
  components: {
    CBox,
    MealScheduleContainer,
  },
  props: {
    isEdited: {
      type: Boolean,
      default: true,
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: null,
    }
  },
  computed: {},
  watch: {
    $route: {
      immediate: true,
      async handler() {
        await this.init()
      },
    },
  },
  methods: {
    async init() {
      const res = await reqAdmin_mealPlans_preview(this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.data = res?.data?.data
    },
  },
}
</script>
